import React from 'react'
import { graphql } from 'gatsby'
import { useIntl, Link } from 'gatsby-plugin-react-intl'

/* COMPONENTS */
import BannerLwart from '../components/BannerLwart'

const NotFoundPage = ({ data }) => {
  const intl = useIntl()

  return <>
    <BannerLwart
      desktop={data.paralax.childImageSharp.gatsbyImageData}
      mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
      className="bannerImage"
    />
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h1 className='mt-5'>{intl.formatMessage({ id: 'paginas.404.title' })}</h1>
          <div className='col-12 col-lg-4 p-0 mt-4 mb-5'>
            <Link to='/' className="d-flex align-items-center justify-content-center mr-3 px-4 w-100 btn-buttonGreen text-uppercase align-middle font-weight-bold">
              <span className="text-uppercase align-middle font-weight-bold">{intl.formatMessage({ id: 'paginas.404.link' })}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default NotFoundPage

export const query = graphql`{
  paralax: file(relativePath: {eq: "blog/header.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "blog/blog.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 400, height: 350, layout: CONSTRAINED)
    }
  }
}
`

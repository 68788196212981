import React from 'react'
import BackgroundImg from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'

export default function BannerLwart (props) {
  const image = getImage(props.desktop)
  const bgImage = convertToBgImage(image)
  const imageMobile = getImage(props.mobile)
  const bgImageMobile = convertToBgImage(imageMobile)
  return (
    <div>
      <BackgroundImg
        {...bgImage}
        Tag="div"
        className={`${props.className} d-lg-block d-none`}
      >
        <div className="container h-100">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="w-100 text-white">
              <h1 className="line-height-normal letter-space-normal mb-2 text-white text-center">{props.titulo}</h1>
              <p className="line-height-normal px-lg-5 mx-md-5 text-center text-white">
                {props.texto}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImg>
      <BackgroundImg
        {...bgImageMobile}
        Tag="div"
        className={`${props.className} d-lg-none d-block`}
      >
        <div className="container h-100">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="w-100 text-white">
              <h1 className="line-height-normal letter-space-normal mb-2 text-white text-center">{props.titulo}</h1>
              <p className="line-height-normal px-lg-5 mx-md-5 text-center text-white">
                {props.texto}
              </p>
            </div>
          </div>
        </div>
      </BackgroundImg>
    </div>
  )
}
